import React from 'react';
import PropTypes from 'prop-types';

// style sheets
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/NavBar.css';

// import component from libraries
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Divider, Button } from '@mui/material';
import TranslateIcon from '@mui/icons-material/Translate';
import i18next from 'i18next';

// import local component
import NavBarDrawer from './NavBarDrawer.component';

// import local data
import contentData from '../Services/services.json';
import logo from '../A-Images/MG_logo.png';

class NavBar extends React.Component {
  constructor(props){
    super(props);
    this.state = {

    };
  }

  renderLanguageButton=() => {
    const currentWindow = window.location.pathname;
    if(this.props.language === 'en'){
      const location = currentWindow.replace("lang=en", "lang=zh");
      return (
        <Button 
          className="navItems langBtn" 
          startIcon={<TranslateIcon className="navIcons"/>}
          href={ location }
          onClick={() => i18next.changeLanguage('zh')}>
          中文
        </Button>
      )
    }else{
      const location = currentWindow.replace("lang=zh", "lang=en");
      return (
        <Button 
          className="navItems langBtn"
          startIcon={<TranslateIcon className="navIcons"/>}
          href={location}
          onClick={() => i18next.changeLanguage('en')}>
          English
        </Button>
      )
    }
  }
  
  render() {
    const serviceItem = contentData.categories;
    const languagePath = '/lang=' + this.props.language;
    return (
      <div id="navbar">
        <Navbar className="nav fixed-top" collapseOnSelect expand="lg">
          <Navbar.Brand className="brandLogo" href={languagePath+"/"}>
            <img className="navLogo d-inline-block align-top" src={logo} alt="MG Aesthetic"/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="navbar-nav" />
          <Navbar.Collapse className="justify-content-end">
            <Nav className="navItemContainer justify-content-end" id="navLink" activeKey={window.location.pathname}>
              <Nav.Link className="navItems" href={languagePath+"/"}>
                {this.props.language === 'en' ? 'Home' : '主页'}
              </Nav.Link>
              <NavDropdown title={this.props.language === 'en' ? 'Services' : '医美疗程'} id="nav-dropdown" className="navItems">
                <Nav.Link id="allService" href={languagePath+"/services"}>
                  {this.props.language === 'en' ? 'All Services' : '所有疗程'}
                </Nav.Link>
                <Divider className="navDivider"/>
                {serviceItem.map((item) => 
                <NavBarDrawer 
                  key={item.id}
                  language={this.props.language}
                  parent={"/services"} 
                  title={this.props.language === 'en' ? item.name : item.nameC} 
                  items={item} />)}
              </NavDropdown>
              <Nav.Link className="navItems" href={languagePath+"/products"}>
                {this.props.language === 'en' ? 'Products' : '护肤产品'}
              </Nav.Link>
              <Nav.Link className="navItems" href={languagePath+"/about"} >
                {this.props.language === 'en' ? 'About' : '关于我们'}
              </Nav.Link>
              {/* <Nav.Link className="navItems" href="/feedback">Feedback</Nav.Link>
              <Nav.Link className="navItems" href="/contact">Contact</Nav.Link>
              <Nav.Link className="navItems" href="/blog">Blog</Nav.Link> */}
            </Nav>
            {this.renderLanguageButton()}
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

NavBar.propTypes = {
  language: PropTypes.string.isRequired
}

export default NavBar;