import React from 'react';
import PropTypes from 'prop-types';

// style sheets
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// components from libraries
import { Swiper, SwiperSlide } from 'swiper/react';
import { Grid, Pagination, Navigation, Autoplay } from 'swiper/modules';

class CarouselCp extends React.Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }

    render() {
        return(
            <Swiper
                slidesPerView={2}
                grid={{ columns: 2 }}
                spaceBetween={30}
                loop={true}
                navigation={false}
                pagination={{ clickable: true }}
                modules={[Grid, Pagination, Navigation, Autoplay]}
                className="mySwiper"
                autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                }}
            >
                { this.props.banda.map((pic, index) => (
                    <SwiperSlide key={index}>
                        <img src={pic} 
                            loading="lazy" 
                            alt={'before and after ' + (index + 1)} 
                            style={{ width: '100%' }}/>
                        <p>
                            {(this.props.language === 'en' )? 
                            ('before and after ') : ('前后对照 ') } 
                            {(index + 1)}
                        </p>
                    </SwiperSlide>
                ))}
            </Swiper>
        )
    }
}

CarouselCp.propTypes = {
    banda: PropTypes.arrayOf(PropTypes.string),
    language: PropTypes.string.isRequired
}

export default CarouselCp;
