import React from 'react';
import PropTypes from 'prop-types';

// stylesheet
import './HomePage.css';

// Third party libraries
import { Button, Grid } from '@mui/material';
import Carousel from 'react-bootstrap/Carousel';
import YouTube from 'react-youtube';

// import local data\
import contentData from './HomeContent.json';

class AboutStore extends React.Component {
    renderPhoneButton=(number) => {
        return(
            <Button className="videoBtn" onClick={()=>{window.open('tel:'+number, '_self')}}>
                {this.props.language === 'en' ? 'Visit Us Today' : '预约请致电'}
            </Button>
        )
      }
    
    render() {
        const youtubeIDs = contentData.youtubeID;
        const opts = {
            width: '100%',
            height: '100%',
            playerVars: {
                autoplay: 0,
            }
        };
        const number = +16265671866;
        return(
            <div>
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid className="videoSection" item xs={12} alignItems="center">
                        <div className="videoContainer">
                            <h1 className="sectionTitle">
                                {this.props.language === 'en' ? 'Our Store' : '我们的诊所'}
                            </h1>
                            {/* main  video link: https://sendvid.com/s1lrni0u */}
                            <Carousel indicators={true} controls={true} interval={50000} >
                                {youtubeIDs.map((video) => 
                                <Carousel.Item key={video.id}>
                                    <YouTube videoId={video.link} 
                                        className="video"
                                        title="MG Aesthetic Center"
                                        loading="lazy"
                                        opts={opts} />
                                </Carousel.Item>)}
                            </Carousel>
                            {this.renderPhoneButton(number)}
                        </div>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

AboutStore.propTypes = {
    language: PropTypes.string.isRequired
}

export default AboutStore;