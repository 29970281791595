import React from 'react';
import PropTypes from 'prop-types';

// style sheets
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/NavBar.css';

// import component from libraries
import { NavDropdown, Nav } from 'react-bootstrap';

class NavBarDrawer extends React.Component {
    constructor(props){
        super(props);
        this.state = {
          openList: false,
          setOpenList: false,
        }
      }

    formatHref=(parent, pathname)=>{
        const prefix = this.props.language === 'en' ? "/lang=en" : "/lang=zh";
        return (prefix + parent + "/" + pathname);
    }

    render() {
        const parent = this.props.parent;
        const title = this.props.title;
        const items = this.props.items.subItems;
        return(
            <NavDropdown title={title} className="dropend subDropdown">
                {items.map((item) =>(
                    <Nav.Link className="subItemLink" href={this.formatHref(parent, item.pathname)} key={item.id}>
                        {this.props.language === 'en' ? item.name: item.nameC} 
                    </Nav.Link> ))}
            </NavDropdown>
        )
    }

}

NavBarDrawer.propTypes = {
    parent: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    items: PropTypes.any.isRequired,
    language: PropTypes.string.isRequired,
}

export default NavBarDrawer;