import React from 'react';
import PropTypes from 'prop-types';

// stylesheet
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// Third party libraries
import { Button, Grid, Card, CardHeader, CardContent, Avatar } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

// import local data
import contentData from './HomeContent.json';


class CustomerFeedback extends React.Component {
    renderPhoneButton=(number) => {
        return(
            <Button className="bookBtn" onClick={()=>{window.open('tel:'+number, '_self')}}>
                {this.props.language === 'en' ? 'Book A Free Consultation' : '免费预约咨询'}
            </Button>
        )
      }
    
    render() {
        const feedbacks = contentData.feedbacks;
        const number = +16265671866;
        return(
            <div className="feedbackContainer">
                <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid className="fbSection" item xs={12} alignItems="center">
                        <h1 className="sectionTitle">{this.props.language === 'en' ? 'Customer Feedbacks' : '客户反馈'}</h1>
                        <Swiper
                            modules={[Autoplay, Pagination, Navigation]}
                            pagination={{ clickable: true }}
                            autoplay={{ 
                                delay: 5000,
                                disableOnInteraction: true,
                            }}
                            slidesPerView={1}
                            spaceBetween={20}
                            breakpoints={{
                                992: {
                                    slidesPerView: 3,
                                    spaceBetween: 30,
                                }
                            }}
                            centeredSlides={true}
                            loop={true}
                            navigation={true}
                        >
                            {feedbacks.map((feedback) => 
                            <SwiperSlide key={feedback.id} >
                                <Card className="fbCard">
                                    <CardHeader
                                        className="cardHeader"
                                        avatar={
                                        <Avatar className="cardProfile" aria-label="feedback">
                                            {feedback.initial}
                                        </Avatar>}
                                        title={feedback.name}
                                        subheader={feedback.date}
                                    />
                                    <CardContent>
                                        <p className="fbContent">
                                            {feedback.review}
                                        </p>
                                    </CardContent>
                                </Card>
                            </SwiperSlide>)}
                        </Swiper>
                    </Grid>
                    <Grid className="btnContainer" item xs={12} alignItem="center">
                        {this.renderPhoneButton(number)}
                    </Grid>
                </Grid>
            </div>
        )
    }
}

CustomerFeedback.propTypes = {
    language: PropTypes.string.isRequired
}

export default CustomerFeedback;