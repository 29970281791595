import React from 'react';
import PropTypes from 'prop-types';

// style sheets
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/Footer.css';

// import component from libraries
import {Divider, Grid, List, ListItemText, ListItemIcon, ListItemButton, Button, Dialog, DialogTitle, DialogContent, IconButton} from '@mui/material';
// import GoogleMapReact from 'google-map-react';
import copy from "copy-to-clipboard";

// import icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import CloseIcon from '@mui/icons-material/Close';
// import RoomIcon from '@mui/icons-material/Room';

// import local items
import logo from '../A-Images/footer_logo.png';
import WeChatQR from '../A-Images/weChatQR.jpg';
import WeChatIcon from '../A-Images/WeChatIcon.jpg';
import TiktokIcon from '../A-Images/TiktokIcon.png';

class Footer extends React.Component {
  constructor(props){
    super(props);
    this.state = {
        storeInfo: {
          address: "1416 S Azusa Ave C3-C4 West Covina CA 91791",
          phoneNum: ["626-567-1866", "626-567-1868"],
          email: "mgaestheticcenter@gmail.com",
          WeChatID: "lady_michelle",
          instagram: "https://instagram.com/mgaestheticcenter?igsh=MXFva2FxNmEzN2Z0bQ==",
          tiktok: "https://www.tiktok.com/@mgaestheticcenterla?_t=8lTjpw6a0ze&_r=1",
          youtube: "https://youtube.com/@MG-hm6dy?si=V8YHdUdQ3JaAYL2C",
          position: {lat: 34.04905291118356, lng: -117.90645880000002}
        },
        dialogOpen: false,
        setDialogOpen: false
    };
  }

  renderPhoneButton=(number) => {
    const num = number.replace(/-/g, "");
    return(
      <ListItemButton className="footerContactBtn" onClick={()=>{window.open('tel: +1'+num, '_self')}}>
        <ListItemIcon>
            <CallIcon className="contactIcon"/>
        </ListItemIcon>
        <ListItemText primary={number} />
      </ListItemButton>
    )
  };

  copyAddress=(address) => {
    copy(address);
    alert(this.props.language === 'en' ? 'Copied MG Aesthetic Center address to clipboard.' : '地址已复制到剪贴板');
  };

  handleDialogOpen=()=>{
    this.setState({ dialogOpen: true });
    this.setState({ setDialogOpen: true });
  }

  handleDialogClose=()=>{
    this.setState({ dialogOpen: false });
    this.setState({ setDialogOpen: false });
  }
  
  render() {
    const info = this.state.storeInfo;
    return (
      <Grid container className="footer" direction="row" justifyContent="center" alignItems="flex-start">
        <Grid item xs={12}><Divider className="footerDivider"/></Grid>
        <Grid item xs={12} md={3}>
            <img className="footerLogo" src={logo} alt="MG Aesthetics Center" />
            <p className="footerValue">
              {this.props.language === 'en' ? 'MG Aesthetic center is the place where inner beauty is combined with achieving the best results in making its client feel and look fabulous.' : '倾城国际医美是旨在将内在美与现代医美技术相结合，让客户无论感觉上或是看起来都更加出色。'}
            </p>
            <p className="footerValue">
            {this.props.language === 'en' ? 'Our mission is to provide detailed, in-person consultation to educate our clients and to make customized aesthetic treatment plan to optimize and enhance the best results for different individuals.' : '我们的使命是为客户提供详细的面对面咨询并制定定制的美学治疗计划，以优化和增强不同个体的最佳效果。'}
            </p>
            <p className="copyright">
              Copyright &copy; 2024 MG Aesthetic Center. 
              All Rights Reserved.
            </p>
        </Grid>
        <Grid item xs={12} md={2}>
            <h4 className="footerTitle">
              {this.props.language === 'en' ? 'Quick Links' : '快速链接'}
            </h4>
            <List>
              <ListItemButton className="footerLink" href={'/lang='+this.props.language}>
                <ListItemText primary={this.props.language === 'en' ? "Home" : '主页'}/>
              </ListItemButton>
              <ListItemButton className="footerLink" href={'/lang='+this.props.language+'/services'}>
                <ListItemText primary={this.props.language === 'en' ? "Services" : '医美疗程'}/>
              </ListItemButton>
              <ListItemButton className="footerLink" href={'/lang='+this.props.language+'/products'}>
                <ListItemText primary={this.props.language === 'en' ? "Products" : '产品'}/>
              </ListItemButton>
              <ListItemButton className="footerLink" href={'/lang='+this.props.language+'/about'}>
                <ListItemText primary={this.props.language === 'en' ? "About" : '关于我们'}/>
              </ListItemButton>
              {/* <ListItemButton className="footerLink" href="/feedback">
                <ListItemText primary="Feedback"/>
              </ListItemButton>
              <ListItemButton className="footerLink" href="/contact">
                <ListItemText primary="Contact"/>
              </ListItemButton> */}
            </List>
        </Grid>
        <Grid item xs={12} md={3}>
          <h4 className="footerTitle">{this.props.language === 'en' ? "Contact" : '联系我们'}</h4>
          <List>
            {info.phoneNum.map((number, index) => 
              <React.Fragment key={index}>
                {this.renderPhoneButton(number)}
              </React.Fragment>
            )}
            <ListItemButton className="footerContactBtn" id="contact" href={"mailto:"+info.email}>
                <ListItemIcon>
                    <MailOutlineIcon className="contactIcon"/>
                </ListItemIcon>
                <ListItemText primary={info.email} primaryTypographyProps={{ style: { whiteSpace: "normal" } }}/>
            </ListItemButton>
            <ListItemButton className="footerContactBtn" href={info.instagram}>
                <ListItemIcon>
                    <InstagramIcon className="contactIcon"/>
                </ListItemIcon>
                <ListItemText primary="mgaestheiccenter" />
            </ListItemButton>
            <ListItemButton className="footerContactBtn" href={info.tiktok}>
                <ListItemIcon>
                  <img id="tiktokIcon" src={TiktokIcon} alt={info.tiktok} className="contactIcon"/>
                </ListItemIcon>
                <ListItemText primary="mgaestheiccenter" />
            </ListItemButton>
            <ListItemButton className="footerContactBtn" href={info.youtube}>
                <ListItemIcon>
                    <YouTubeIcon className="contactIcon"/>
                </ListItemIcon>
                <ListItemText primary="mgaestheiccenter" />
            </ListItemButton>
            <ListItemButton className="footerContactBtn" onClick={this.handleDialogOpen}>
                <ListItemIcon>
                    <img id="wechatIcon" src={WeChatIcon} alt={info.WeChatID} className="contactIcon"/>
                </ListItemIcon>
                <ListItemText primary={info.WeChatID} />
            </ListItemButton>
            <Dialog className="dialogWindow" open={this.state.dialogOpen} onClose={this.handleDialogClose}>
              <DialogTitle className="dialogTitle">
                {this.props.language === 'en' ? "Scan WeChat QR Code" : '扫描二维码添加我们'}
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={this.handleDialogClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
              <DialogContent>
                <img className="dialogImg" src={WeChatQR} alt="WeChatID" />
              </DialogContent>
            </Dialog>
          </List>
        </Grid>
        <Grid item xs={12} md={3}>
            <h4 className="footerTitle">{this.props.language === 'en' ? "Location" : '地址'}</h4>
            <Button className="footerLocation" onClick={()=>this.copyAddress(info.address)} startIcon={<LocationOnIcon/>}>
              {info.address} {this.props.language === 'en' ? "(Click to copy address)" : '(点击复制)'}
            </Button>
            <div style={{ height: '30vh', width: '100%' }}>
              {/* <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
              zoom={17} 
              defaultCenter={info.position} 
              yesIWantToUseGoogleMapApiInternals>
                <RoomIcon className="footerMapPin" lat={info.position.lat} lng={info.position.lng} />
              </GoogleMapReact> */}
            </div>
        </Grid>
      </Grid>
    )
  }
}

Footer.propTypes = {
  language: PropTypes.string.isRequired
}

export default Footer;