// default import
import React from 'react';
import PropTypes from 'prop-types';

// stylesheet
import './ServiceCp.css';

// import third-party libraries
import { Grid } from '@mui/material';

// import local components
import BannerCp from '../A-Reusable/BannerCp.component';
import CarouselCp from '../A-Reusable/CarouselCp.component';

// import local data
import homepageInfo from '../HomePage/HomeContent.json';

class ServiceCp extends React.Component {

    renderCarousel = (banda) => {
        if (banda.length !== 0) {
            return (
                <Grid item xs={12}>
                    <h2 className="servCpTitle"> 
                        {this.props.language === 'en' ? 'Before and After' : '前后对比'}
                    </h2>
                    <CarouselCp banda={banda} language={this.props.language}/>
                </Grid>
            )
        }
    }

    renderDes = (description) =>{
        const fullDescription = description.replace(/\\n/g, '\n');
        const desLines = fullDescription.split('\n');

        return(
            <>
                { (desLines.length > 1) ? 
                (desLines.map((line, index) => (
                    <p className="servCpDes" key={index}>
                        {line}
                    </p>
                ))) : (fullDescription)}
            </>
        )
    }

    render() {
        const service = this.props.service;
        const background = homepageInfo.pageSelections[0].src;
        return(
            <div className="pageBody serviceCp">
                <BannerCp
                    language = {this.props.language}
                    title={this.props.language === 'en' ? service.name : service.nameC} 
                    slogan={this.props.language ==='en' ? "Aim to provide the best services for you" : '致力于为您提供最好的服务'} 
                    background={background}/>
                <Grid container className="servCpRow" spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} md={4}>
                        <h2 className="servCpTitle">
                            {this.props.language === 'en' ? service.name : service.nameC}
                        </h2>
                        <p className="servCpDes">
                            {this.renderDes(this.props.language === 'en' ?service.description : service.descriptionC)}
                        </p>
                    </Grid>
                    <Grid item md={3} sx={{ display: {xs: "none", sm:'none', md:'block'} }}>
                        <img className="servCpImg" src={service.src} alt={service.alt} />
                    </Grid>
                    {this.renderCarousel(service.banda)}
                </Grid>
            </div>
        )
    }
}

ServiceCp.propTypes = {
    service: PropTypes.shape({
        name: PropTypes.string.isRequired,
        nameC: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        descriptionC: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired,
        alt: PropTypes.string.isRequired,
        banda: PropTypes.arrayOf(PropTypes.string)
    }),
    language: PropTypes.string.isRequired
}

export default ServiceCp;