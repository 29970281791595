import React from "react";
import { Suspense } from 'react';
import './i18n';
import i18next from 'i18next';

// Stylesheet
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Router library
import { Routes, Route } from 'react-router-dom';

// Pages Import
import NavBar from './A-Reusable/NavBar.component';
import Footer from './A-Reusable/Footer.component';
import HomePage from './HomePage/HomePage';
import AboutUs from './AboutUs/AboutUs';
import Services from './Services/Services';
import ServiceCp from "./Services/ServiceCp";
import Products from './Products/Products';
import NotFound from "./NotFound/NotFound";

// import Feedback from './Feedback/Feedback';
// import Contact from './Contact/Contact';
// import Blog from './Blog/Blog';

// import local data
import serviceData from './Services/services.json';

const theme = createTheme({
  typography: {
    fontFamily: [ 
      "Noto Serif SC",
      "Shippori Mincho",
      "serif"
    ].join(','),
  }
});

function App() {
  const serviceLink = serviceData.categories;
  const language = getCurrentLng();

  function getCurrentLng (){
    return i18next.language || window.localStorage.i18nextLng || '';
  }

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <Suspense fallback={null}>
          {language === 'en' ? <NavBar language="en" /> : <NavBar language="zh" />}
          <Routes>
            {/* English Routes */}
            <Route path="/" element={ <HomePage language={language}/> } />
            <Route path="/lang=en" element={ <HomePage language="en"/> } />
            <Route path="/lang=en/services">
              <Route index element={ <Services language="en"/> } />
              {serviceLink.map((serviceItem) =>
                <React.Fragment key={serviceItem.id}>
                  {serviceItem.subItems.map((service) =>  (
                      <Route exact key={service.pathname} 
                      path={service.pathname}  
                        element={ <ServiceCp service={service} language="en"/> }
                      />
                  ))}
                </React.Fragment>
              )}
            </Route>
            <Route path="/lang=en/products" element={ <Products language="en"/> } />
            <Route path="/lang=en/about" element={ <AboutUs language="en"/> } />
            <Route path="/lang=en/*" element={ <NotFound language="en"/> } />
            {/* Chinese Routes */}
            <Route path="/lang=zh" element={ <HomePage language="zh"/> } />
            <Route path="/lang=zh/services">
              <Route index element={ <Services language="zh"/> } />
                {serviceLink.map((serviceItem) =>
                  <React.Fragment key={serviceItem.id}>
                    {serviceItem.subItems.map((service) => (
                        <Route exact key={service.pathname}
                          path={service.pathname} 
                          element={ <ServiceCp service={service} language="zh"/> }
                        />
                    ))}
                  </React.Fragment>
                )}
            </Route>
            <Route path="/lang=zh/products" element={ <Products language="zh"/> } />
            <Route path="/lang=zh/about" element={ <AboutUs language="zh"/> } />
            <Route path="/lang=zh/*" element={ <NotFound language="zh"/> } />
            <Route path="/*" element={ <NotFound language={language}/> } />
          </Routes>
        </Suspense>
        {language === 'en' ? <Footer language="en" /> : <Footer language="zh" />}
        {/* Temporary cancelled pages
        <Route path="/feedback" element={ <Feedback /> } />
        <Route path="/contact" element={ <Contact /> } />
        <Route path="/blog" element={ <Blog /> } /> */}   
      </div>
    </ThemeProvider>
  );
}

export default App;
