import React from 'react';
import PropTypes from 'prop-types';

// import local components
import Banner from './1-Banner.component';
import PageSelection from './2-PageSelection.component';
import ServiceProduct from './3-ServiceProduct.component';
import AboutStore from './4-AboutStore.component';
import CustomerFeedback from './5-CustomerFeedback.component';

class HomePage extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            
        };
    }

    render() {
        return(
            <div className="homepage pageBody">
                <Banner language={this.props.language}/>
                <PageSelection language={this.props.language}/>
                <ServiceProduct language={this.props.language}/>
                <AboutStore language={this.props.language}/>
                <CustomerFeedback language={this.props.language}/>
            </div>
        )
    }
}

HomePage.propTypes = {
    language: PropTypes.string.isRequired
}

export default HomePage;