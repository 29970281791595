// default import
import React from 'react';
import PropTypes from 'prop-types';

// import third-party libraries

// import local components
import './NotFound.css';

class NotFound extends React.Component {
    render() {
        return(
            <div className="pageBody">
                <h1 className="notFound">
                    {this.props.language === 'en' ? 
                    "Oops! The page was not found"
                    :
                    "对不起，您访问的页面不存在" }
                </h1>
            </div>
        )
    }
}

NotFound.propTypes = {
    language: PropTypes.string.isRequired
}

export default NotFound;