// default import
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// stylesheet
import './AboutUs.css';

// import third-party libraries
import { Grid, Button } from '@mui/material'

const RenderPicOnSide = ({language, section, onRight}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const wordLimit = language === 'en' ? 100 : 280;
    const fullDescription = (language === 'en' ? section.description : section.descriptionC).replace(/\\n/g, '\n');
    const desLines = fullDescription.split('\n');
    let hideContent = false;

    const decideContentToShow = (des) => {
        const isOverWordLimit = (language ==='en' ? des.split(" ").length : des.length) > wordLimit;
        let contentToShow = ""
        if(isOverWordLimit){
            if(desLines.length > 1){
                let totalWord = 0;
                let para = 0;
                for(const p of desLines){
                    const newLen = (language ==='en' ? p.split(" ").length : p.length);
                    if(totalWord + newLen > wordLimit){
                        const overLimit = totalWord + newLen - wordLimit;
                        if(language === 'en'){
                            const lengthToKeep = p.split(" ").length - overLimit;
                            contentToShow = desLines.slice(0, para);
                            const newPara = p.split(" ").splice(0, lengthToKeep).join(" ");
                            contentToShow[para] = newPara
                            hideContent = true;
                        } else {
                            const lengthToKeep = p.length - overLimit;
                            contentToShow = desLines.slice(0, para);
                            const newPara = p.substring(0, lengthToKeep);
                            contentToShow[para] = newPara
                            hideContent = true;
                        }
                        return contentToShow;
                    }else{
                        totalWord += newLen
                        para++;
                    }
                }
                return desLines;
            }else{
                contentToShow = (language ==='en' ? des.split(" ").splice(0, wordLimit).join(" ") : des.substring(0, wordLimit));
                hideContent = true;
                return contentToShow;
            }
        }else{
            if(desLines.length > 1){
                return desLines;
            }
            return des;
        }
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderDescription = () => {
        const fullContent = (desLines.length > 1) ? desLines : fullDescription;
        const contentToShow = isExpanded ? fullContent : decideContentToShow(fullDescription);
        const shouldShowExpandButton = !isExpanded && hideContent;
        return (
          <>
            { (desLines.length > 1) ? 
                (contentToShow.map((line, index) => (
                    <p className="cardDes" key={index}>
                        {line}
                    </p>
                ))) : (contentToShow)}
            { shouldShowExpandButton > 0 && 
                <span>... 
                    <Button className="expandComp" size="small" onClick={toggleExpand}>
                        {language === 'en' ? 'Read More' : '展开'}
                    </Button>
                </span>}
            {isExpanded && 
                <span>
                    <Button className="expandComp" size="small" onClick={toggleExpand}>
                        {language === 'en' ? 'Read less' : '收起'}
                    </Button>
                </span>}
          </>
        );
    };

    return(
            <Grid container className="aboutRow" spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                { onRight ? (
                    <React.Fragment>
                        <Grid item xs={12} md={4}>
                            <h2 className="aboutTitle">
                                {language=== 'en' ? section.name : section.nameC}
                            </h2>
                            <p className="aboutDes">{renderDescription()}</p>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <img className="aboutImg" loading="lazy" src={section.src} alt={section.name}/>
                        </Grid>
                    </React.Fragment>
                 ) : (
                    <React.Fragment>
                    <Grid item xs={12} md={3}>
                        <img className="aboutImg" loading="lazy" src={section.src} alt={section.name}/>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <h2 className="aboutTitle">
                            {language=== 'en' ? section.name : section.nameC}
                        </h2>
                        <p className="aboutDes">{renderDescription()}</p>
                    </Grid>
                    </React.Fragment>
                 )}
            </Grid>
    );
}

RenderPicOnSide.propTypes = {
    section: PropTypes.shape({
        name: PropTypes.string.isRequired,
        nameC: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        descriptionC: PropTypes.string.isRequired,
        src: PropTypes.string.isRequired
    }),
    onRight: PropTypes.bool.isRequired,
    language: PropTypes.string.isRequired
}


export default RenderPicOnSide;
