import React from 'react';
import PropTypes from 'prop-types';

// Third party libraries
import { Button, Grid, List, ListItem, ListItemText } from '@mui/material';

// data retrieval
import contentData from './HomeContent.json';
import serviceData from '../Services/services.json';
import productData from '../Products/products.json';

class ServiceProduct extends React.Component {
    render() {
        const serviceDesE = contentData.ServiceInfo.descriptionE;
        const serviceDesC = contentData.ServiceInfo.descriptionC;
        const productDesE = contentData.StarProduct.descriptionE;
        const productDesC = contentData.StarProduct.descriptionC;
        const services = serviceData.categories[0].subItems;
        const products = productData.categories[0].subItems;
        return(
            <React.Fragment>
                <div className="starSection">
                    <h1 className="sectionTitle">{this.props.language === 'en' ? 'Star Services' : '明星项目'}</h1>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} md={3}>
                            <img className="starImg" loading="lazy" src="https://files.btlnet.com/aest/file_items/f9cfc8b0-fbeb-47fb-9adf-05d2ab620025/Exion_PIC_Web-product-page-unit-top_EN100.01_1687867446_original.png" alt="Star Services"/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <p className="starDes">{this.props.language === 'en' ? serviceDesE : serviceDesC}</p>
                            <List>
                                {services.map((service) => 
                                <ListItem key={service.id} className="starList">
                                    <ListItemText primary={this.props.language === 'en' ? service.name : service.nameC} />
                                </ListItem>
                                )}     
                            </List>
                            <Button className="starBtn" href={'/lang=' + this.props.language + '/services'}>
                                {this.props.language === 'en' ? 'More Services' : '了解更多'}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className="starSection">
                    <h1 className="sectionTitle">{this.props.language === 'en' ? 'Star Product' : '明星产品'}</h1>
                    <Grid container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                        <Grid item xs={12} md={4}>
                            <p className="starDes">{this.props.language === 'en' ? productDesE : productDesC}</p>
                            <List>
                                {products.map((product) => 
                                <ListItem key={product.id} className="starList">
                                    <ListItemText primary={this.props.language === 'en' ? product.name : product.nameC} />
                                </ListItem>
                                )}     
                            </List>
                            <Button className="starBtn" href={'/lang=' + this.props.language + '/products'}>   
                                {this.props.language === 'en' ? 'More Products' : '了解更多'}
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <img className="starImg" loading="lazy" src="https://images4.imagebam.com/ed/0e/e0/MESZKMS_o.jpg" alt="Star Product"/>
                        </Grid>
                    </Grid>
                </div>
            </React.Fragment>
        )
    }
}

ServiceProduct.propTypes = {
    language: PropTypes.string.isRequired
}

export default ServiceProduct;