import React from 'react';
import PropTypes from 'prop-types';

import { Button } from '@mui/material';

// style sheet
import './styles/BannerCp.css';

class BannerCp extends React.Component {
    renderPhoneButton=(number) => {
        return(
            <Button className="bannerBtn" onClick={()=>{window.open('tel:'+number, '_self')}}>
                {this.props.language === 'en' ? 'Book A Free Consultation Now' : '预约免费咨询'}
            </Button>
        )
      }
    
    render() {
        const number = +16265671866;

        const style = {
            "background": `url(${this.props.background})`,
            "backgroundRepeat": "no-repeat",
            "backgroundPosition": "center",
            "backgroundSize": "cover",
        };

        return(
            <div className="bannerCpContainer" style={style}>
                <div className="bannerCpFloat">
                    <h1 className="bannerCpTitle">{this.props.title}</h1>
                    <h3 className="bannerCpSlogan">{this.props.slogan}</h3>
                    {this.renderPhoneButton(number)}
                </div>
            </div>
        )
    }
}

BannerCp.propTypes = {
    background: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    slogan: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
}

export default BannerCp;