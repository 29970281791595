// default import
import React from 'react';
import PropTypes from 'prop-types';

// stylesheet
import './AboutUs.css';

// import third-party libraries

// import local components
import BannerCp from '../A-Reusable/BannerCp.component';
import RenderPicOnSide from './RenderPicOnSide';

// import local data
import content from './teamInfo.json';
import homepageInfo from '../HomePage/HomeContent.json';

class AboutUs extends React.Component {
    render() {
        const teamInfo = content.OurTeam;
        const background = homepageInfo.pageSelections[2].src;
        return(
            <div className="pageBody aboutUs">
                <BannerCp
                    language={this.props.language}
                    title={this.props.language === 'en' ? "About Us" : '关于我们'} 
                    slogan={this.props.language === 'en' ? "Learn more about our values and experiences." : '了解更多关于我们的故事'}
                    background={background}
                />
                <RenderPicOnSide language={this.props.language} section={teamInfo[0]} onRight={false}/>
                {/* <RenderPicOnSide language={this.props.language} section={teamInfo[1]} onRight={true}/> */}
                <RenderPicOnSide language={this.props.language} section={teamInfo[2]} onRight={true}/>
                <RenderPicOnSide language={this.props.language} section={teamInfo[3]} onRight={false}/>
            </div>
        )
    }
}

AboutUs.propTypes = {
    language: PropTypes.string.isRequired
}

export default AboutUs;