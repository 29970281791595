import React from 'react';
import PropTypes from 'prop-types';

// Third party libraries
import { Grid, Button, Paper } from '@mui/material'

// import local data
import contentData from './HomeContent.json';

class PageSelection extends React.Component {
    
    render() {
        const data = contentData.pageSelections;
        return(
            <div className="pageSelection">
                <Grid container spacing={2}>
                    {data.map((section) => 
                        <Grid item xs={12} md={4} key={section.id} className="selectionContainer">
                            <Paper className="selectionCard">
                                <img className="selectionImg" src={section.src} alt={section.alt} />
                                <div className="selectionSec">
                                    <h1 className="selectionTitle">
                                        {this.props.language === 'en' ? section.titleE : section.titleC}
                                    </h1>
                                    <Button href={'/lang=' + this.props.language + section.link} className="selectionBtn">
                                        {this.props.language === 'en' ? 'Read More' : '了解更多'}
                                    </Button>
                                </div>
                            </Paper>
                        </Grid>   
                    )}
                </Grid>
            </div>
        )
    }
}

PageSelection.propTypes = {
    language: PropTypes.string.isRequired
}

export default PageSelection;