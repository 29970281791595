import React from 'react';
import PropTypes from 'prop-types';

// style sheet
import './HomePage.css';

class Banner extends React.Component {
    
    render() {
        if(this.props.language === 'en') {
            return(
                <div id="bannerContainer">
                    <div className="bannerTitle">
                        <h1 className="storeName">MG Aesthetic Center</h1>
                        <h3 className="slogan">Meet MG Aesthetic Center. Meet the best of you.</h3>
                    </div>
                </div>
            )
        } else {
            return(
                <div id="bannerContainer">
                    <div className="bannerTitle">
                        <h1 className="storeName">倾城国际医美</h1>
                        <h3 className="slogan">遇见倾城，遇见最好的自己。</h3>
                    </div>
                </div>
            )
        }
    }
}

Banner.propTypes = {
    language: PropTypes.string.isRequired
}

export default Banner;