// default import
import React from 'react';
import PropTypes from 'prop-types';

// stylesheet
import './Products.css'

// import third-party libraries
import { Grid, Box } from '@mui/material';

// import local components
import BannerCp from '../A-Reusable/BannerCp.component';
import CardCp from '../A-Reusable/CardCp';
import products from './products.json';

//import local data
import homepageInfo from '../HomePage/HomeContent.json';

class Products extends React.Component {
    render() {
        const background = homepageInfo.pageSelections[1].src;
        return(
            <div className="pageBody productPage">
                <BannerCp 
                    language={this.props.language}
                    title={this.props.language === 'en' ? "Our Products" : '护肤产品'} 
                    slogan={this.props.language === 'en' ? "Let us find the best products for you!" : '让我们为您找到最适合您的护肤产品'} 
                    background={background}/>
                <Grid container className="productRow" spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} md={4}>
                        <h2 className="productTitle">
                            {this.props.language === 'en' ? "Our Products" : '护肤产品'}
                        </h2>
                        <p className="productDes">
                        {this.props.language === 'en' ? 
                            "Welcome to MG Aesthetic Center - Your Destination for Premium Skincare Solutions\nAt MG Aesthetic Center, we are committed to providing you with the most advanced and effective skincare products available in the market. Our carefully curated selection includes high-end brands known for their innovative formulas and exceptional results. Whether you are looking to rejuvenate your skin, address specific dermatological concerns, or simply maintain a youthful and radiant complexion, we have the perfect solution for you."
                             : '欢迎来到倾城国际医美——您优质护肤解决方案的目的地。\n在倾城，我们致力于为您提供市场上最先进、最有效的护肤产品。我们精心挑选的产品包括知名品牌，以其创新配方和出色效果而闻名。无论您是想要焕发肌肤活力，解决特定的皮肤问题，还是仅仅想要保持年轻、光彩照人的肤色，我们都有完美的解决方案供您选择。'}
                        </p>
                    </Grid>
                    <Box component='Grid' sx={{display:{xs: 'none', sm:'none', md:'block'}}} item md={3}>
                        <img className="productImg" src="https://mgaestheticusa.com/wp-content/uploads/2020/12/MG-Aesthetic-Center.jpg" alt="Our Services"/>
                    </Box>
                </Grid>
                {products.categories.map((productType) =>  (
                    <div key={productType.id} className="productRow">
                        <h2 className="productTitle">
                            {this.props.language === 'en' ? productType.name.toUpperCase() : productType.nameC}
                        </h2>
                        <Grid className="productRow cardRow" container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                            {productType.subItems.map((product) => (
                                <Grid item xs={6} md={3} key={product.id}>
                                    <CardCp language={this.props.language} item={product} isService={false} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
              ))}
            </div>
        )
    }
}

Products.propTypes = {
    language: PropTypes.string.isRequired
}

export default Products;