// default import
import React from 'react';
import PropTypes from 'prop-types';

// style sheets
import './Services.css';

// import third-party libraries
import { Grid } from '@mui/material';

// import local components
import BannerCp from '../A-Reusable/BannerCp.component';
import CardCp from '../A-Reusable/CardCp';
import services from './services.json';
import homepageInfo from '../HomePage/HomeContent.json';

class Services extends React.Component {
    render() {
        const background = homepageInfo.pageSelections[0].src;
        return(
            <div className="pageBody servicePage">
                <BannerCp
                    language={this.props.language}
                    title={this.props.language === 'en' ? "All Services" : '医美疗程'} 
                    slogan={this.props.language === 'en' ? "Aim to provide the best services for you" : '致力于给您提供最好的服务'}
                    background={background}/>
                <Grid container className="serviceRow" spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                    <Grid item xs={12} md={4}>
                        <h2 className="serviceTitle">{this.props.language === 'en' ? 'Our Services' : '我们提供的项目'}</h2>
                        <p className="serviceDes">
                            {this.props.language === 'en' ? 
                            'MG Aesthetic Center Offers an inclusive menu of non-surgical treatment options, including but not limited to, Thermage FLX, Enlighten Pico Genesis laser treatment, Enlighten Pico Genesis tattoo removal, Thread lift, IV therapy, Anti-aging injections, Dermal fillers, Fat reduction injection, Lose weight & Body contour, Filorga hydro Injection, MG hydro injection, PRP Micro-needling, RF Micro-needling, Sculptra injection, HydroImpact facial treatment, Valmont facial treatment, Cellcosmet facial treatment, MG deep cleansing facial treatment, MG special facial treatment, LED light acne treatment, Semi-permanent tattoo service, etc.'
                            : 
                            '倾城国际医美提供一系列非手术治疗选择，包括但不限于热玛吉 FLX、皮秒激光治疗、皮秒纹身去除、线雕、静脉注射疗法、抗衰老注射、玻尿酸填充剂、减脂注射、减肥和身体塑形、菲洛嘉水光注射、MG水光注射、PRP微针、RF微针、童颜针注射、HydroImpact面部护理、法尔曼面部护理、瑞妍面部护理、MG深层清洁面部护理、MG特殊面部护理、LED光疗痘痘治疗、半永久纹身服务等。'}
                        </p>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <img className="serviceImg" src="https://images4.imagebam.com/d5/9f/e2/MESW4HS_o.jpg" alt="Our Services"/>
                    </Grid>
                </Grid>
                {services.categories.map((serviceCategory) =>  (
                    <div key={serviceCategory.id} className="serviceRow">
                        <h2 className="serviceTitle">
                            {this.props.language === 'en' ? serviceCategory.name.toUpperCase() : serviceCategory.nameC}
                        </h2>
                        <Grid className="serviceRow cardRow" container spacing={2} direction="row" justifyContent="center" alignItems="flex-start">
                            {serviceCategory.subItems.map((service) => (
                                <Grid item xs={12} md={4} key={service.id}>
                                    <CardCp language = {this.props.language} item={service} isService={true} />
                                </Grid>
                            ))}
                        </Grid>
                    </div>
              ))}
            </div>
        )
    }
}

Services.propTypes = {
    language: PropTypes.string.isRequired
}

export default Services;