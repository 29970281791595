// default import
import React, { useState } from 'react';
import PropTypes from 'prop-types';

// style sheet
import './styles/CardCp.css';

// import third-party libraries
import { Button, Card, CardMedia, CardContent } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const CardCp = ({language, item, isService}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const wordLimit = language === 'en' ? 50 : 120;
    const description = language === 'en' ? item.description : item.descriptionC;
    const fullDescription = description.replace(/\\n/g, '\n');
    const desLines = fullDescription.split('\n');
    let hideContent = false;

    const decideContentToShow = (des) => {
        const isOverWordLimit = (language ==='en' ? des.split(" ").length : des.length) > wordLimit;
        let contentToShow = ""
        if(isOverWordLimit){
            if(desLines.length > 1){
                let totalWord = 0;
                let para = 0;
                for(const p of desLines){
                    const newLen = (language ==='en' ? p.split(" ").length : p.length);
                    if(totalWord + newLen > wordLimit){
                        const overLimit = totalWord + newLen - wordLimit;
                        if(language === 'en'){
                            const lengthToKeep = p.split(" ").length - overLimit;
                            contentToShow = desLines.slice(0, para);
                            const newPara = p.split(" ").splice(0, lengthToKeep).join(" ");
                            contentToShow[para] = newPara
                            hideContent = true;
                        } else {
                            const lengthToKeep = p.length - overLimit;
                            contentToShow = desLines.slice(0, para);
                            const newPara = p.substring(0, lengthToKeep);
                            contentToShow[para] = newPara
                            hideContent = true;
                        }
                        return contentToShow;
                    }else{
                        totalWord += newLen
                        para++;
                    }
                }
                return desLines;
            }else{
                contentToShow = (language ==='en' ? des.split(" ").splice(0, wordLimit).join(" ") : des.substring(0, wordLimit));
                hideContent = true;
                return contentToShow;
            }
        }else{
            if(desLines.length > 1){
                return desLines;
            }
            return des;
        }
    }

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    const renderDescription = () => {
        const fullContent = (desLines.length > 1) ? desLines : fullDescription;
        const contentToShow = isExpanded ? fullContent : decideContentToShow(fullDescription);
        const shouldShowExpandButton = !isExpanded && hideContent;
        return (
          <>
            { (desLines.length > 1) ? 
                (contentToShow.map((line, index) => (
                    <p className="cardDes" key={index}>
                        {line}
                    </p>
                ))) : (contentToShow)}
            { shouldShowExpandButton > 0 && 
                <Button className="expandComp" size="small" onClick={toggleExpand}>
                    {language === 'en' ? '... Read More' : '... 展开'}
                </Button>}
            {isExpanded && 
                <Button className="expandComp" size="small" onClick={toggleExpand}>
                    {language === 'en' ? 'Read less' : '收起'}
                </Button>}
          </>
        );
    };

    return(
            <Card className="cardCp">
                <CardMedia className="cardImg" component='img' image={item.src} alt={item.alt} />
                <CardContent className="cardContent">
                    <h4 className="cardTitle">{language === 'en' ? item.name : item.nameC}</h4>
                    { isService &&
                        <p className="cardDes">
                            {renderDescription()}
                        
                        </p>
                    }
                </CardContent>
                { isService &&
                    <div className="btnContainer">
                        <Button className="cardBtn" href={'/lang=' + language + '/services/' + item.pathname}>
                            {language === 'en' ? 'More Detail' : '了解更多'} <ArrowForwardIcon/>
                        </Button>
                    </div>
                }
            </Card>
    );
}

CardCp.propTypes = {
    item: PropTypes.any.isRequired,
    isService: PropTypes.bool.isRequired,
}


export default CardCp;
